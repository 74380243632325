<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-building"></i>
                </div>
              </div>
              <h4 class="title">เมนูจัดการองค์กร</h4>
              <p class="border-bottom"></p>
            </div>

            <div class="section-title">
              <h5 class="text-primary">วิธีตั้งค่าเมนูการแสดงผล</h5>
            </div>
            <!-- header -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">กดแท็บเมนู "Company tabbars"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal1>
                  <img
                    src="/images/screen/company/company5.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal1"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company5.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">การสร้างเมนู กดปุ่ม "Add Menu"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal2>
                  <img
                    src="/images/screen/company/company6.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal2"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company6.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">เลือกรูปแบบที่ต้องการ</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal3>
                  <img
                    src="/images/screen/company/company7.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal3"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company7.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">เลือกรูปแบบ "Overview"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal4>
                  <img
                    src="/images/screen/company/company8.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal4"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company8.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      เพิ่มส่วนประกอบกดปุ่ม "Add Elements"
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal5>
                  <img
                    src="/images/screen/company/company9.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal5"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company9.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.3</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      เลือกรูปแบบคอนเทนต์ที่จะแสดงข้อมูล
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal6>
                  <img
                    src="/images/screen/company/company10.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal6"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company10.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.4</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">แก้ไขข้อมูลตามแบบฟอร์ม</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal7>
                  <img
                    src="/images/screen/company/company11.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal7"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company11.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.5</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">กดปุ่มบันทึก</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal8>
                  <img
                    src="/images/screen/company/company12.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal8"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company12.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.6</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      สามารถเปลี่ยนตำแหน่งโดยการลากที่กล่อง Elements ขึ้นลง
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal9>
                  <img
                    src="/images/screen/company/company13.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal9"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company13.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.7</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      การลบ Elements ให้กดปุ่ม "..." หลังกล่อง Elements
                      แล้วกดปุ่ม delete
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal10>
                  <img
                    src="/images/screen/company/company14.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal10"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company14.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3.8</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      แก้ไขข้อมูลทุกอย่างเรียบร้อยแล้วให้กดปุ่ม "บันทึก"
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal11>
                  <img
                    src="/images/screen/company/company15.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal11"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company15.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">4.1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">เลือกรูปแบบ "My Course"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal12>
                  <img
                    src="/images/screen/company/company16.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal12"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company16.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">4.2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">แก้ไขข้อความแล้วกดบันทึก</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal13>
                  <img
                    src="/images/screen/company/company17.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal13"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company17.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">5.1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">เลือกรูปแบบ "Search"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal14>
                  <img
                    src="/images/screen/company/company18.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal14"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company18.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">5.2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">แก้ไขข้อความแล้วกดบันทึก</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal15>
                  <img
                    src="/images/screen/company/company20.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal15"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company20.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">6.1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">เลือกรูปแบบ "Setting"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal16>
                  <img
                    src="/images/screen/company/company19.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal16"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company19.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">6.2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">แก้ไขข้อความแล้วกดบันทึก</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal17>
                  <img
                    src="/images/screen/company/company21.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal17"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/company/company21.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "companyTabbar",
    };
  },
  components: {
    library,
  },
};
</script>

